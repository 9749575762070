<!-- src/views/widget/Widget.vue -->
<template>
    <div style="margin-top: 5rem;">
        <h2 id="frameResponse"></h2>
        <div id="arcopayContainer"></div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import store from "@/store";

export default {
    name: "Widget",
    data() {
        return {
            codigo: "",
        }
    },
    store,
    computed: mapState({
        step: (state) => state.formulario.step,
        api_token: (state) => state.api_token,
        formulario: (state) => state.formulario,
        data_matricula: (state) => state.formulario.data_matricula,
        solicitud: (state) => state.solicitud,
        preload: (state) => state.preload,
    }),
    methods: {
        calcularFechaProxima() {
            const hoy = new Date();

            // Sumar un mes
            hoy.setMonth(hoy.getMonth() + 1);

            // Formatear la fecha en DD-MM-YYYY con guiones
            const dia = hoy.getDate().toString().padStart(2, '0');
            const mes = (hoy.getMonth() + 1).toString().padStart(2, '0'); // Los meses son de 0 a 11
            const anio = hoy.getFullYear();

            const fechaFormateada = `${dia}-${mes}-${anio}`;
            return fechaFormateada;
        }
    },
    beforeCreate() {
        

    },
    mounted() {
        this.codigo = this.$route.params.codigo;
        console.log(this.codigo)

        //en caso de que ningun codigo haya sido setteado se devolvera al inicio
        if (this.codigo == "") {
            console.log("salgo")
            this.$router.push({ path: "/", hash: null });
        } else {
            // Buscar id solicitud
            var idSolicitud = this.codigo.slice(14);
            console.log(idSolicitud)
            store.commit("setTokenEnlace", this.codigo);
            store.commit("setIdSolicitud", idSolicitud);
            axios
                .get(process.env.VUE_APP_AXIOS_BASE_URL + "afterbanks/token/" + this.codigo)
                .then((resp) => {
                    let solicitud = resp.data.data;
                    store.commit("setSolicitud", solicitud);
                    store.commit("setAfiliado", solicitud.web);
                    setTimeout(() => {
                        store.commit("setPreload", false);
                    }, 1000);
                })
                .catch(() => {
                    alert("no")
                    // En caso de no coincidir token, redirigir
                    store.commit("setPreload", false);
                    this.$router.push({ path: "/home", hash: null });
                });
        }
        
        // Definir los parámetros del widget en el ámbito global
        let parameters = {
            "id": this.codigo,
            // Acción del widget
            "action": "read",
            // Configuración del widget
            "countryCode": "ALL",
            "banksShownPSD2": "ALL",
            // "banksShownPSD2": "none",
            "banksShownV3": "ALL",
            "defaultBank": "",
            "autoStart": 0,
            "defaultLanguage": "ES",
            "showSandbox": 1,
            "showSplashScreen": 0,
            "showCheckTerms": 0,
            "URLredirectAfterOK": "",
            // "URLredirectAfterOK": "http://ibancar.local:8080/?callback-arcopay=OK",
            "URLredirectAfterKO": "",
            // "URLredirectAfterKO": "http://ibancar.local:8080/?callback-arcopay=KO",
            // Parámetros READ PSD2
            "dailyFrequency": 4,
            "validUntil": this.calcularFechaProxima()
        };

        // Crear el elemento de script
        let iframe = document.createElement("script");
        iframe.setAttribute('src', 'https://static.afterbanks.com/appmain/PSD2ExternalForm/js/external_mixed.js');

        // Escuchar el evento 'load' del script
        iframe.onload = () => {
            // Inicializar el iframe después de que el script haya cargado
            if (typeof window.afterbanksIframeContainer === "undefined") {
                window.afterbanksIframeContainer = document.getElementById("arcopayContainer");
            }

            window.afterbanksIframeContainer.innerHTML = `
      <div style="max-width: 95%; height: 100%; margin: 0 auto;">
        <iframe title="Afterbanks Arcopay Widget PSD2" id="iframeArcopay"
                src="https://www.afterbanks.com/appmain/PSD2ExternalForm/?action=${parameters.action}&showSplashScreen=${parameters.showSplashScreen}&showCheckTerms=${parameters.showCheckTerms}&showSandbox=${parameters.showSandbox}&client=${parameters.client || ''}"
                frameborder="no" framespacing="0" border="0" width="100%" height="900">
        </iframe>
      </div>
    `;

            // Obtener referencia al iframe
            if (typeof window.afterbanksIframe === "undefined") {
                window.afterbanksIframe = document.getElementById("iframeArcopay");
            }

            // Escuchar el evento 'load' del iframe
            window.afterbanksIframe.addEventListener("load", () => {
                window.afterbanksIframe.contentWindow.postMessage(parameters, "https://www.afterbanks.com/");
            });

            // Escuchar mensajes del iframe
            window.addEventListener("message", (e) => {
                if ((null == parameters.scroll || parameters.scroll !== 1) && e.data && e.data.code !== null && e.data.message !== null) {
                    let a = e.data.code;
                    let t = e.data.message;

                    switch (a) {
                        case 99999999:
                            window.afterbanksIframe.style.height = t + "%"; 
                            window.scrollTo(0, 0);
                            break;
                    }
                }
            }, false);
        };

        // Agregar el script al head
        document.head.appendChild(iframe);
    }


};
</script>