const redirectRoutes = [
    {
        path: '/prestamos-asnef-acto',
        redirect: {
            path: '/prestamos-asnef-en-el-acto',
            status: 301
        }
    }
];

export default redirectRoutes;