const prestamoAsnef = [
    // Parent Path
    {
        path: '/prestamos-asnef',
        name: 'prestamos asnef',
        component: () =>
            import ( /* webpackChunkName: "prestamos_con_asnef" */ '../views/landings/PrestamoAsnef.vue'),
    },
    
    // Child Paths
    {
        path: '/prestamos-con-asnef-y-nomina-rapidos',
        name: 'Préstamo con Asnef y nómina Rápidos',
        component: () =>
            import ( /* webpackChunkName: "prestamos_con_asnef_y_nomina_rapidos" */ '../views/PrestamosConAsnefYNominaRapidos.vue')
    },
    {
        path: '/prestamos-asnef-en-el-acto',
        name: 'Préstamos con ASNEF en el acto',
        component: () =>
            import ( /* webpackChunkName: "prestamos_asnef_en_el_acto" */ '../views/PrestamoActoAsnef.vue')
    },
    {
        path: '/prestamos-asnef-sin-intermediarios',
        name: 'Préstamos con ASNEF sin intermediarios',
        component: () =>
            import ( /* webpackChunkName: "prestamos_asnef_sin_intermediarios" */ '../views/landings/PrestamosAsnefSinIntermediarios.vue')
    },
    {
        path: '/prestamos-particulares-asnef',
        name: 'prestamos particulares con asnef',
        component: () =>
            import ( /* webpackChunkName: "prestamos_particulares_asnef" */ '../views/landings/PrestamosParticularesAsnef.vue')
    },
    {
        path: '/prestamo-1000-euros-asnef',
        name: 'prestamo 1000 euros asnef',
        component: () =>
            import ( /* webpackChunkName: "prestamo_1000_euros_asnef" */ '../views/landings/Prestamo1000EurosAsnef.vue')
    },
    {
        path: '/prestamos-urgentes-asnef',
        name: 'prestamos urgentes asnef',
        component: () =>
            import ( /* webpackChunkName: "prestamos_con_asnef" */ '../views/landings/PrestamosAsnefUrgentes.vue')
    },
];

export default {
    prestamoAsnef
}